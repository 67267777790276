import React, { useState } from "react"
import classNames from "classnames"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import Accordion from "../../components/accordion"
import "./styles.css"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useTranslation } from "../../utils"
import Footer from "../../sections/footer"
import { useAnalytics } from "../../hooks/useTracking"

const tabs = {
  PLANS: "plans",
  SETUP: "setup",
  FIREWALL: "firewall",
  ANTIVIRUS: "antivirus",
  GENERAL: "general",
}

const FaqPage = () => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(tabs.PLANS)
  const analytics = useAnalytics()

  return (
    <Layout>
      <SEO title={t("FAQ")} />
      <div className="faq-page">
        <Header />

        <div className="faq-page__questions">
          <div className="container">
            <div className="faq-page__title">{t("Questions & Answers")}</div>

            <div className="faq-page__nav">
              <div
                onClick={() => {
                  analytics.clickChangeCategory("PLANS")
                  setTab(tabs.PLANS)
                }}
                className={classNames("faq-page__nav-item", {
                  "faq-page__nav-item--active": tab === tabs.PLANS,
                })}
              >
                {t("Plans")}
              </div>
              <div
                onClick={() => {
                  analytics.clickChangeCategory("SETUP")
                  setTab(tabs.SETUP)
                }}
                className={classNames("faq-page__nav-item", {
                  "faq-page__nav-item--active": tab === tabs.SETUP,
                })}
              >
                {t("Setup")}
              </div>
              <div
                onClick={() => {
                  analytics.clickChangeCategory("FIREWALL")
                  setTab(tabs.FIREWALL)
                }}
                className={classNames("faq-page__nav-item", {
                  "faq-page__nav-item--active": tab === tabs.FIREWALL,
                })}
              >
                {t("Firewall")}
              </div>
              <div
                onClick={() => {
                  analytics.clickChangeCategory("ANTIVIRUS")
                  setTab(tabs.ANTIVIRUS)
                }}
                className={classNames("faq-page__nav-item", {
                  "faq-page__nav-item--active": tab === tabs.ANTIVIRUS,
                })}
              >
                {t("Antivirus")}
              </div>
              <div
                onClick={() => {
                  analytics.clickChangeCategory("GENEREAL")
                  setTab(tabs.GENERAL)
                }}
                className={classNames("faq-page__nav-item", {
                  "faq-page__nav-item--active": tab === tabs.GENERAL,
                })}
              >
                {t("General")}
              </div>
            </div>

            {tab === tabs.PLANS && (
              <div className="faq-page__content">
                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("What is WebTotem?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t("WhatIsWebTotemAnswer")}
                    </span>
                  </Accordion>
                </div>

                <div className="faq-page__content-divider display-xs-none display-sm-none display-md-none" />

                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("Is WebTotem suitable for you?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "Whether you are a small business with a single site or a company with multiple sites we got you covered. We recommend using WebTotem if:"
                      )}
                      <ul>
                        <li>
                          {t(
                            "Your website is built on cms like WordPress, Drupal, Joomla or any other custom PHP-based solution"
                          )}
                        </li>
                        <li>
                          {t("Your website is on shared hosting or cloud")}
                        </li>
                        <li>
                          {t("Your website is the face of your business")}
                        </li>
                        <li>
                          {t("You care about your business’s reputation")}
                        </li>
                      </ul>
                    </span>
                  </Accordion>
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("How does it work?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t("It’s pretty simple to get started.")}
                      <ul>
                        <li>{t("Choose the plan & sign up.")}</li>
                        <li>{t("Add your website to the dashboard.")}</li>
                        <li>
                          {t("Install the agent and activate protection.")}
                        </li>
                      </ul>
                    </span>
                  </Accordion>
                </div>
              </div>
            )}

            {tab === tabs.GENERAL && (
              <div className="faq-page__content">
                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("Why don’t I see my verification mail?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        'Check other sections of your mail service. It is possible that the letter got into spam. If you use gmail, also check the "Promotions" section'
                      )}
                    </span>
                  </Accordion>
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("What API-keys are used for?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "Typically, the Currently, our API-Keys are used for activating WordPress Plugin. But we are to add more integrations soon, including Zapier, where API-Keys will be required for activation."
                      )}
                    </span>
                  </Accordion>
                </div>

                <div className="faq-page__content-divider display-xs-none display-sm-none display-md-none" />

                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("I don’t see data in server resources ")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "There are two cases why this can happen. Perhaps the module is still processing your site. Or your server configurations do not meet requirements. For this module to work server should give access to read the files:"
                      )}
                      <ul>
                        <li>/proc/meminfo</li>
                        <li>/proc/cpuinfo</li>
                        <li>/proc/stat</li>
                      </ul>
                      {t("or to run shell_exec.")}
                    </span>
                  </Accordion>
                </div>
              </div>
            )}
            {tab === tabs.SETUP && (
              <div className="faq-page__content">
                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t(
                          "Why can’t i activate Wordpress plugin with API-Keys?"
                        )}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "It is required to copy API-Key immediately after it has been generated. Since we don't store API-Keys with authentic namings for the sake of security issues. If you did not copy it from generation window, we recommend you to delete it, generate a new one again and copy it with original naming."
                      )}
                    </span>
                  </Accordion>
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t(
                          "Where should I upload the file that I downloaded during manual installation?"
                        )}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "You must upload this file to the root directory of your site where the root files are located (usually in the directory with index.php)"
                      )}
                    </span>
                  </Accordion>
                </div>

                <div className="faq-page__content-divider display-xs-none display-sm-none display-md-none" />

                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t(
                          "What to do if there are problems with the installation?"
                        )}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      <ul>
                        <li>
                          {t(
                            "Check the version of your PHP, at the moment, versions below 7.0 are not supported by our agent manager, however we plan to do this."
                          )}
                        </li>
                        <li>
                          {t(
                            "If you have an old version of PHP, we recommend that you upgrade for your own safety."
                          )}
                        </li>
                        <li>
                          {t(
                            "Check permissions on your site and users rights."
                          )}
                        </li>
                        <li>
                          <FormattedMessage
                            id="Give permissions to edit the downloaded file to the user who runs PHP. To find out which user php is running from, you need to find the PHP configuration file, for example, in /etc/php/fpm/pool.d/www.conf It has a www section and user and group values."
                            defaultMessage="Give permissions to edit the downloaded file to the user who runs PHP. To find out which user php is running from, you need to find the PHP configuration file, for example, in /etc/php/fpm/pool.d/www.conf It has a www section and user and group values."
                            values={{
                              blue: (...chunks) => (
                                <div className="wt-text wt-text--blue">
                                  {chunks}
                                </div>
                              ),
                            }}
                          />
                        </li>
                        <li>
                          {t(
                            "Make sure that you correctly added the site to your account (check the HTTP or HTTPS protocol)."
                          )}
                        </li>
                      </ul>
                    </span>
                  </Accordion>
                </div>
              </div>
            )}
            {tab === tabs.FIREWALL && (
              <div className="faq-page__content">
                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("Why doesn’t firewall block the attacks?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "Typically, firewall needs two days to finish training completely. Training is required to analyze your website traffic and thus reduce false positives."
                      )}
                      <br />
                      {t(
                        "If after two days after installation the firewall does not block attacks, please contact support."
                      )}
                    </span>
                  </Accordion>
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("Does GDN send my data to other Webtotem clients?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "Thanks for the question. You don't have to worry about your personal data. GDN option shares data collected between your websites and does not share it with other WebTotem clients."
                      )}
                    </span>
                  </Accordion>
                </div>

                <div className="faq-page__content-divider display-xs-none display-sm-none display-md-none" />

                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t(
                          "Why is the firewall module empty in the report, although there are results in the cabinet?"
                        )}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "Typically, the firewall only shows blocked attacks in the report."
                      )}
                      <br />
                      {t(
                        "If there are blocked attacks that are shown in your account, but which are not in the report, then there may be problems on our side."
                      )}
                    </span>
                  </Accordion>
                </div>
              </div>
            )}
            {tab === tabs.ANTIVIRUS && (
              <div className="faq-page__content">
                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("How does antivirus work?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "Our antivirus scans every 6 hours and scans automatically each time the filesystem changes. In other words, if you upload a new file to your website our antivirus scans it immediately. There is also an option to start manual scanning by clicking the rescan button in the right top of the module.  Manual scanning shows the same results if no changes to filesystem has occured since the last automatic scanning."
                      )}
                    </span>
                  </Accordion>
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("How does manual scan work?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        "To start scanning you need to click on rescan button in antivirus logs module. In case no changes happened to your filesystem since the last automatic scan the logs will show the same results after manual rescanning."
                      )}
                    </span>
                  </Accordion>
                </div>

                <div className="faq-page__content-divider display-xs-none display-sm-none display-md-none" />

                <div className="wt-flex-1">
                  <Accordion
                    title={
                      <span className="faq-page__content-title">
                        {t("How do I delete an infected file?")}
                      </span>
                    }
                  >
                    <span className="faq-page__content-answer">
                      {t(
                        'It is impossible to completely delete a file marked as infected by an antivirus using our service. This can be a vital file for your website. You can quarantine this file. To do this, select the site you need in your personal account. Go to the antivirus module, click the "SHOW MORE" button, configure the filter for infected files and click the "trash bin" icon next to the file name.'
                      )}
                    </span>
                  </Accordion>
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer />
      </div>
    </Layout>
  )
}

export default FaqPage
