import React, { useState } from "react"
import classNames from "classnames"
import "./styles.css"
import ArrowIcon from "../../images/arrow.svg"

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <article
      className={classNames("accordion", { "accordion-closed": !isOpen })}
    >
      <div className="accordion-title" onClick={() => setOpen(!isOpen)}>
        {title}
        <img
          src={ArrowIcon}
          alt="arrow"
          className="wt-margin-left-10 display-none display-lg-none"
        />
      </div>
      <div className="accordion-body">
        <div className="accordion-content">{children}</div>
      </div>
    </article>
  )
}

export default Accordion
